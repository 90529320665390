<template>
  <div class="mainregisteremail">
    <div class="container-modal-registro">
      <div class="container-modal-title">
        <h1>CREA TU CUENTA</h1>
      </div>
      &nbsp;
      <form class="container-main-form" @submit.prevent="register()">
        <div class="container-label">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"
              style="display: flex; justify-content:center; align-items: center;"><box-icon name='envelope'></box-icon></span>
            <input type="email" class="form-control" placeholder="Ingresa Un Correo Electrónico" aria-label="Username"
              aria-describedby="basic-addon1" v-model="state.email" required>
          </div>
        </div>
        <div class="container-label">
          <div class="input-group mb-2">
            <span class=" input-group-text" id="basic-addon1"
              style="display: flex; justify-content:center; align-items: center;"><box-icon name='key'></box-icon></span>
            <input type="password" class="form-control" placeholder="Ingresa Una Contraseña" aria-label="Contraseña"
              aria-describedby="basic-addon1" v-model="state.password" required>
          </div>
        </div>
        <div class="input-errors-1" v-for="error of v$.password.$errors" :key="error.$uid">
          <div class="error-msg-1">
            {{ error.$message }}
          </div>
        </div>
        <div class="container-label">
          <div class="input-group mb-3">
            <span class=" input-group-text" id="basic-addon1"
              style="display: flex; justify-content:center; align-items: center;"><box-icon name='key'></box-icon></span>
            <input type="password" class="form-control" placeholder="Confirmar Contraseña " aria-label="Contraseña"
              aria-describedby="basic-addon1" v-model="state.password2" required>
          </div>
        </div>
        <div class="input-errors-1" v-for="error of v$.password2.$errors" :key="error.$uid">
          <div class="error-msg-1">
            {{ error.$message }}
          </div>
        </div>
        <div class="d-flex justify-content-center btn-register">
          <button class="btn btn-outline-primary" style="display: flex; justify-content: center; align-items: center;"
            type="submit" :disabled="response.loading">
            <span>Crear Cuenta</span>
            &nbsp;
            <div class="spinner-border spinner-border-sm float-end" role="status" v-show="response.loading">
              <span class="visually-hidden">Loading...</span>
            </div>
          </button>
        </div>
      </form>
      <div class="container-link">
        <div class="p-2">
          <span class="link1">¿Ya tienes una cuenta con nosotros?</span>
        </div>
        <div>
          <router-link class="text-decoration-none" to="/login">
            <span class="link">Iniciar Sesión</span>
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { computed, reactive } from "vue";
import useValidate from "@vuelidate/core";
import { UserService } from "@/services";
import {
  required,
  minLength,
  maxLength,
  helpers,
  email,
  sameAs,
} from "@vuelidate/validators";

export default {
  setup() {
    const state = reactive({
      email: "",
      password: "",
      password2: "",
    });
    const rules = computed(() => {
      return {
        email: {
          required: helpers.withMessage(
            "Por favor ingrese un correo.",
            required
          ),
          email: helpers.withMessage("Ingrese un correo válido.", email),
        },
        password: {
          required: helpers.withMessage("Ingrese una contraseña.", required),
          minLength: helpers.withMessage("Mínimo 5 caracteres.", minLength(5)),
          maxLength: helpers.withMessage("Máximo 15 caracteres.", maxLength(15)),
        },
        password2: {
          sameAs: helpers.withMessage(
            "Las contraseñas no coinciden.",
            sameAs(state.password)
          ),
        },
      };
    });
    const v$ = useValidate(rules, state);
    return {
      state,
      v$,
    };
  },
  data() {
    return {
      response: {
        loading: false
      }
    }
  },
  mounted() {
    this.v$.$validate();
  },
  methods: {
    async register() {
      this.response.loading = true;
      if (!this.v$.$error) {
        const data = { email: this.state.email, password: this.state.password };
        await UserService.signupEmail(data).then((response) => {
          this.$swal({
            position: "top-center",
            icon: "success",
            text: response.data.message,
            showConfirmButton: false,
            timer: 5000,
          });
          this.axios.defaults.headers.common["Autorization"] = "Bearer" + response.data;
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("email", response.data.email);
          window.location.href = "/createAccount";
          this.response.loading = false;
        }).catch((error) => {
          this.response.loading = false;
          this.$swal({
            icon: "error",
            text: error.response.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        });
      }
    },
    submitRegister() {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: "Error en el registro",
        showConfirmButton: false,
        timer: 1500,
      });
    },
  },
};
</script>

<style land="scss" scoped>
@import "../styles/registeremail.scss";
</style>
